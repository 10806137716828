import React, { createContext, useCallback, useState } from 'react';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { StorageFolder } from 'store/storage/types';
import { Props } from './types';

const initialValuesInfo: StorageFolder['form'] = {
  title: '',
  description: '',
};

export const StorageFolderContext = createContext<Props>({
  form: { values: initialValuesInfo },
} as Props);

const StorageFolderProvider: React.FC = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);

  const { t } = useTranslation('storage');

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`folder_form.${key}`, params),
    [t]
  );

  const formValidationSchema = yup.object().shape({
    title: yup
      .string()
      .required(tBase('info_step.required_field_error'))
      .max(100, tBase('info_step.limit_character_error', { count: 100 })),
    description: yup
      .string()
      .optional()
      .max(2000, tBase('info_step.limit_character_error', { count: 2000 })),
  });

  const nextStep = () => {
    setCurrentStep(2);
  };

  const form = useFormik({
    initialValues: initialValuesInfo,
    onSubmit: nextStep,
    validationSchema: formValidationSchema,
    validateOnChange: true,
    isInitialValid: false,
  });

  return (
    <StorageFolderContext.Provider
      value={{
        form,
        currentStep,
        setCurrentStep,
      }}
    >
      {children}
    </StorageFolderContext.Provider>
  );
};

export default StorageFolderProvider;
